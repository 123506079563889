/**
 * 科目
 */

export const Subject = [
  { id: 'CHINESE', value: '语文' },
  { id: 'MATHEMATICS', value: '数学' },
  { id: 'ENGLISH', value: '英语' },
  { id: 'PHYSICS', value: '物理' },
  { id: 'CHEMISTRY', value: '化学' },
  { id: 'BIOLOGY', value: '生物' },
  { id: 'MORALITY_AND_RULE_OF_LAW', value: '道德与法治' },
  { id: 'GEOGRAPHY', value: '地理' },
  { id: 'HISTORY', value: '历史' },
  { id: 'MUSIC', value: '音乐' },
  { id: 'FINE_ARTS', value: '美术' },
  { id: 'SPORTS', value: '体育' },
  { id: 'SCIENCE', value: '科学' },
  { id: 'INFORMATION_TECHNOLOGY', value: '信息技术' },
  { id: 'MENTAL_HEALTH', value: '心理健康' },
  { id: 'LABOR_EDUCATION', value: '劳动教育' },
  { id: 'COMPREHENSIVE_PRACTICAL_ACTIVITIES', value: '综合实践活动' },
  { id: 'CALLIGRAPHY_EDUCATION', value: '书法教育' },
  { id: 'SAFETY_EDUCATION', value: '安全教育' },
  { id: 'PRESCHOOL_EDUCATION', value: '幼儿教育' },
  { id: 'SPECIAL_EDUCATION', value: '特殊教育' }
]
